<template>
    <nav>
        <v-app-bar flat>
            <v-row :class="[$vuetify.breakpoint.smAndDown ? 'pt-3' : 'pt-8']">
                <v-col v-if="$vuetify.breakpoint.smAndDown" cols=auto align-self="center" class="pa-0">
                    <v-app-bar-nav-icon class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
                </v-col>
                <v-col v-if="!$vuetify.breakpoint.smAndDown" cols=auto align-self="center">
                    <router-link tag="span" :to="{name: 'landing'}"><v-img style="cursor: pointer;" height=150 width=180 contain src="@/assets/logo_app.svg" /></router-link>
                </v-col>
                <v-col v-if="!$vuetify.breakpoint.smAndDown" cols=auto :class="['d-flex', 'align-center', $vuetify.breakpoint.lgAndUp ? 'title' : '']" align-self="center">
                    <router-link v-if="this.$store.state.loggedInFlag == '1'" style="cursor: pointer;" tag="span" :to="{name: 'WebAppDashboard'}" class="mr-10 d-inline-flex align-center">
                            <v-icon :class="[this.$route.name=='WebAppDashboard' ? 'primary--text' : 'grey--text text--darken-2']" class="mr-2" >dashboard</v-icon>
                            <span :class="[this.$route.name=='WebAppDashboard' ? 'primary--text' : 'grey--text text--darken-2', 'font-weight-medium']">{{ $t("navbar.dashboard") }}</span>
                    </router-link>
                    <router-link v-if="this.$store.state.loggedInFlag == '2'" style="cursor: pointer;" tag="span" :to="{name: 'WebAppHome'}" class="mr-10 d-inline-flex align-center">
                            <v-icon :class="[this.$route.name=='WebAppHome' ? 'primary--text' : 'grey--text text--darken-2']" class="mr-2">home</v-icon>
                            <span :class="[this.$route.name=='WebAppHome' ? 'primary--text' : 'grey--text text--darken-2', 'font-weight-medium']">{{ $t("navbar.home") }}</span>
                    </router-link>
                    <router-link style="cursor: pointer;" tag="span" :to="{name: 'WebAppEventHome'}" class="mr-10 d-inline-flex align-center">
                            <v-icon :class="[this.$route.name=='WebAppEventHome' ? 'primary--text' : 'grey--text text--darken-2']" class="mr-2" >event</v-icon>
                            <span :class="[this.$route.name=='WebAppEventHome' ? 'primary--text' : 'grey--text text--darken-2', 'font-weight-medium']">{{ $t("navbar.offers") }}</span>
                    </router-link>
                    <router-link style="cursor: pointer;" tag="span" :to="{name: 'WebAppMap'}" class="mr-10 d-inline-flex align-center">
                            <v-icon :class="[this.$route.name=='WebAppMap' ? 'primary--text' : 'grey--text text--darken-2']" class="mr-2" >map</v-icon>
                            <span :class="[this.$route.name=='WebAppMap' ? 'primary--text' : 'grey--text text--darken-2', 'font-weight-medium']">{{ $t("navbar.map") }}</span>
                    </router-link>
                    <router-link v-if="this.$store.state.loggedInFlag != '2'" style="cursor: pointer;" tag="span" :to="{name: 'WebAppUsers'}" class="mr-10 d-inline-flex align-center">
                            <v-icon :class="[this.$route.name=='WebAppUsers' ? 'primary--text' : 'grey--text text--darken-2']" class="mr-2" >person</v-icon>
                            <span :class="[this.$route.name=='WebAppUsers' ? 'primary--text' : 'grey--text text--darken-2', 'font-weight-medium']">{{ $t("navbar.volunteers") }}</span>
                    </router-link>
                    <router-link v-if="this.$store.state.loggedInFlag == '2'" style="cursor: pointer;" tag="span" :to="{name: 'WebAppOrganisations'}" class="mr-10 d-inline-flex align-center">
                            <v-icon :class="[this.$route.name=='WebAppOrganisations' ? 'primary--text' : 'grey--text text--darken-2']" class="mr-2" >business</v-icon>
                            <span :class="[this.$route.name=='WebAppOrganisations' ? 'primary--text' : 'grey--text text--darken-2', 'font-weight-medium']">{{ $t("navbar.organisations") }}</span>
                    </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col v-if="!$vuetify.breakpoint.smAndDown && loggedIn" cols=auto align-self="center" class="d-flex align-center pa-0">
                    <router-link :to="{name: 'WebAppProfile', params: {id: $store.state.loggedInId}}">
                        <v-hover v-slot:default="{ hover }" style="cursor: pointer;">
                            <v-card class="d-flex align-center pa-2" height="auto" :elevation="hover ? 6 : 2" style="border-radius: 40px">
                            <span v-if="$vuetify.breakpoint.md" class="ml-2 mr-3 caption primary--text">{{stripText($store.state.name, 16)}}</span>
                            <span v-else class="ml-2 mr-3 primary--text">{{$store.state.name}}</span>
                            <ProfilePhoto class="mr-2" :src="$store.state.photo" heightImage="40" />
                            </v-card>
                        </v-hover>
                    </router-link>
                </v-col>
                <v-col cols=auto align-self="center">
                    <router-link :to="{ name: 'logout' }">
                        <v-btn v-if="loggedIn" text color="grey">
                            <span :class="$vuetify.breakpoint.md ? 'caption' : ''">{{ $t("navbar.logout") }}</span>
                            <v-icon right>exit_to_app</v-icon>
                        </v-btn>
                    </router-link>
                    <span v-if="!loggedIn">
                        <router-link tag="span" :to="{ name: 'login' }"><v-btn :small="$vuetify.breakpoint.smAndDown" class="mr-3"> {{ $t("navbar.login") }}</v-btn></router-link>
                        <router-link tag="span" :to="{ name: 'register' }"><v-btn :small="$vuetify.breakpoint.smAndDown">{{ $t("navbar.register") }}</v-btn></router-link>
                    </span>
                </v-col>
                <v-col v-if="!$vuetify.breakpoint.smAndDown" cols=auto align-self="center">
                  <LocaleChanger />
                </v-col>
            </v-row>
        </v-app-bar>
        <v-navigation-drawer app color="white" v-model="drawer" v-if="$vuetify.breakpoint.smAndDown" temporary>
            <v-list flat class="pt-4">
                <v-list-item-group v-model="item" color="primary">
                    <v-list-item
                      v-for="(item, i) in filteredItems"
                      :key="i"
                      link
                      :to="{ name: item.route, params: item.params }"
                      @click="drawer=false"
                    >
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <LocaleChanger styled="menu" @click="drawer=false"/>
        </v-navigation-drawer>
    </nav>
</template>

<script>
import ProfilePhoto from '@/components/partials/ProfilePhoto'
import utilitiesMixin from '@/mixins/utilitiesMixin'
import LocaleChanger from '@/components/partials/LocaleChanger'
    export default {
        components: {LocaleChanger, ProfilePhoto},
        mixins: [utilitiesMixin],
        data() {
            return {
                drawer: false,
                item: 1,
                items: [
                    { public: true, icon: 'event', text: this.$t("navbar.offers"), route: 'WebAppEventHome'},
                    { public: true, icon: 'map', text: this.$t("navbar.map"), route: 'WebAppMap'},
                    { public: false, icon: 'account_circle', text: this.$t("navbar.profile"), route: 'WebAppProfile', params: {id: this.$store.state.loggedInId || 0}},
                ]
            }
        },
        computed: {
            loggedIn() {
                return this.$store.getters.loggedIn
            },
            filteredItems() {
                return this.items.filter((item) => {
                    return this.loggedIn || item.public
                })
            }
        },
        created() {
            if (this.$store.state.loggedInFlag == '1') {
                this.items.unshift({ public: true, icon: 'person', text: this.$t("navbar.volunteers"), route: 'WebAppUsers'})
                this.items.unshift({ public: false, icon: 'dashboard', text: this.$t("navbar.dashboard"), route: 'WebAppDashboard'})
            } else if (this.$store.state.loggedInFlag == '2') {
                this.items.unshift({ public: false, icon: 'home', text: this.$t("navbar.home"), route: 'WebAppHome'},)
                this.items.push({ public: false, icon: 'business', text: this.$t("navbar.organisations"), route: 'WebAppOrganisations'},)
            }
            else {
                this.items.push({ public: true, icon: 'person', text: this.$t("navbar.volunteers"), route: 'WebAppUsers'})
            }
        }
    }
</script>

<style lang="scss">
</style>
